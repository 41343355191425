import { useMemo } from 'react';
import { Space, Typography } from 'antd';
import {
  QueryData,
  useCreateCategoryContext,
} from 'pages/CreateCategory/CreateCategoryContext';
import { AddQueryModalWithTrigger } from 'components/AddQueryModal/AddQueryModalWithTrigger';
import { Query } from './Query/Query';

import styles from './Queries.module.scss';

export const Queries = ({
  subcategoryIndex,
}: {
  subcategoryIndex?: number;
}) => {
  const { category, setCategory } = useCreateCategoryContext();

  const queries = useMemo(() => {
    if (!subcategoryIndex && subcategoryIndex !== 0) {
      return category.queries;
    }

    const subcategory = category.subcategories?.[subcategoryIndex];

    return subcategory?.queries;
  }, [category.queries, category.subcategories, subcategoryIndex]);

  const handleCreate = (queryData: QueryData) => {
    if (!subcategoryIndex && subcategoryIndex !== 0) {
      setCategory((prev) => ({
        ...prev,
        queries: [...(prev.queries || []), queryData],
      }));
      return;
    }

    setCategory((prev) => ({
      ...prev,
      subcategories: prev.subcategories?.map((subcategory, i) => {
        if (i !== subcategoryIndex) {
          return subcategory;
        }

        return {
          ...subcategory,
          queries: [...(subcategory.queries || []), queryData],
        };
      }),
    }));
  };

  return (
    <div className={styles['queries-wrapper']}>
      <Space className={styles['queries-space']} direction="vertical" size={16}>
        <div className={styles['queries-header']}>
          <Typography className={styles['queries-header-title']}>
            Queries
          </Typography>
          <AddQueryModalWithTrigger onCreate={handleCreate} />
        </div>
        <Space
          className={styles['queries-space']}
          direction="vertical"
          size={8}
        >
          {queries?.map((query, index) => (
            <Query
              key={query.name}
              query={query}
              subcategoryIndex={subcategoryIndex}
              index={index}
            />
          ))}
        </Space>
      </Space>
    </div>
  );
};
