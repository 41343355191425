import { useEffect, useState } from 'react';
import {
  App,
  Button,
  Divider,
  Form,
  Popconfirm,
  Steps,
  Typography,
} from 'antd';
import {
  collection,
  doc,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import ArrowRightIcon from 'common/icons/ArrowRight';
import { Collections, ROUTES } from 'common/constants';
import { useNavigate } from 'react-router-dom';
import CheckMarkIcon from 'common/icons/CheckMark';
import { db } from 'api/firebaseConfig';
import { deleteCollection } from 'common/helpers/deleteCollection';
import { useLanguage } from 'common/helpers';
import { SubcategoriesStep } from './components/SubcategoriesStep/SubcategoriesStep';
import { CategorySettingsStep } from './components/CategorySettingsStep/CategorySettingsStep';
import {
  useCreateCategoryContext,
  withCreateCategoryContext,
} from './CreateCategoryContext';

import styles from './CreateCategory.module.scss';

const steps = [
  {
    title: 'General',
    content: <CategorySettingsStep />,
  },
  {
    title: 'Template Queries',
    content: <SubcategoriesStep />,
  },
];

const items = steps.map(({ title }) => ({ key: title, title }));

const CreateCategoryInner = () => {
  const { category, setCategory, isEditing, isLoading, categoryId } =
    useCreateCategoryContext();
  const { language } = useLanguage();

  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const handleFinish = () => {
    form.validateFields();
  };

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const onNextClickHandler = async () => {
    form
      .validateFields()
      .then((values) => {
        setCurrentStep(currentStep + 1);
        setCategory((prev) => {
          const updatedCategory = {
            ...prev,
            name: values.categoryName,
            emoji: values.emoji,
            hasSubcategories: !values.noSubcategory,
          };

          if (
            !values.noSubcategory &&
            !updatedCategory.subcategories?.some(
              (subcategory) => subcategory.name === 'Other'
            )
          ) {
            updatedCategory.subcategories = (
              updatedCategory.subcategories || []
            ).concat({
              name: 'Other',
              queries: prev.queries,
            });
          }

          if (values.noSubcategory && !updatedCategory.queries?.length) {
            const subcategories = updatedCategory.subcategories || [];

            updatedCategory.queries = subcategories.flatMap((sub) =>
              (sub.queries || []).map((query, index) => ({
                key: query.id,
                name: query.name,
                id: query.id,
                order: index,
                prompt: query.prompt,
                questions: query.questions,
              }))
            );
          }

          return updatedCategory;
        });
      })
      .catch(() => undefined);
  };

  const onCancelClickHandler = () => {
    navigate(ROUTES.HOME);
  };

  const onBackClickHandler = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCreateCategory = async () => {
    if (!category.queries?.length && !category.hasSubcategories) {
      notification.error({
        message: 'Error',
        description: 'Create at least one Query',
      });
      return;
    }
    if (isEditing && categoryId) {
      await Promise.all([
        deleteCollection(
          db,
          [
            Collections.Locales,
            language,
            Collections.Categories,
            categoryId,
            Collections.Queries,
          ],
          [Collections.Questions]
        ),
        deleteCollection(
          db,
          [
            Collections.Locales,
            language,
            Collections.Categories,
            categoryId,
            Collections.Subcategories,
          ],
          [Collections.Queries, Collections.Questions]
        ),
      ]);
    }
    const batch = writeBatch(db);
    const localeRef = doc(db, Collections.Locales, language);
    const categoryRef =
      isEditing && categoryId
        ? doc(localeRef, Collections.Categories, categoryId)
        : doc(collection(localeRef, Collections.Categories));

    batch.set(localeRef, {
      locale: language,
    });
    batch.set(categoryRef, {
      name: category.name,
      emoji: category.emoji,
      timestamp: serverTimestamp(),
    });

    if (category.hasSubcategories) {
      const subCategoryHasQuery = category.subcategories?.find(
        (subCategory) => subCategory?.queries?.length
      );
      if (!subCategoryHasQuery) {
        notification.error({
          message: 'Error',
          description: 'Create at least one Query',
        });
        return;
      }
      category.subcategories?.forEach((subcategory) => {
        const subcategoryRef = doc(
          collection(categoryRef, Collections.Subcategories)
        );

        batch.set(subcategoryRef, {
          name: subcategory.name,
        });

        subcategory.queries?.forEach((query, index) => {
          const queryRef = doc(collection(subcategoryRef, Collections.Queries));

          batch.set(queryRef, {
            name: query.name,
            prompt: query.prompt,
            order: index,
          });

          query.questions?.forEach((question) => {
            const questionRef = doc(
              collection(queryRef, Collections.Questions)
            );

            batch.set(questionRef, {
              key: question.key,
              question: question.question,
            });
          });
        });
      });
    } else {
      category.queries?.forEach((query, index) => {
        const queryRef = doc(collection(categoryRef, Collections.Queries));

        batch.set(queryRef, {
          name: query.name,
          prompt: query.prompt,
          order: index,
        });

        query.questions?.forEach((question) => {
          const questionRef = doc(collection(queryRef, Collections.Questions));

          batch.set(questionRef, {
            key: question.key,
            question: question.question,
          });
        });
      });
    }

    if (category.subcategories?.length || !category.hasSubcategories) {
      batch
        .commit()
        .then(() => {
          navigate(ROUTES.HOME);
          notification.success({
            message: 'Success',
            description: 'Category was created successfully',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Category was not created',
          });
        });
    } else {
      notification.error({
        message: 'Error',
        description: 'No subcategories have been created',
      });
    }
  };

  useEffect(() => {
    setCategory(category);
  }, []);

  return (
    <Form
      form={form}
      id="createCategory"
      name="createCategory"
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{
        categoryName: category.name,
        emoji: category.emoji,
        noSubcategory: category.subcategories
          ? category.subcategories?.length === 0
          : true,
      }}
    >
      <div className={styles['create-category']}>
        <header className={styles['create-category-header']}>
          {currentStep === 0 && (
            <Popconfirm
              title="Are you sure you want to close this page?"
              description="All unsaved data will be lost"
              onConfirm={onCancelClickHandler}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={styles['create-category-header-button-text']}
                type="text"
              >
                Cancel
              </Button>
            </Popconfirm>
          )}
          {currentStep === 1 && (
            <Button
              className={styles['create-category-header-button-text']}
              type="text"
              onClick={onBackClickHandler}
            >
              Back
            </Button>
          )}
          <Typography.Title level={1}>
            {isEditing ? 'Edit' : 'Create'} Category
          </Typography.Title>
          {currentStep === 0 && (
            <Button
              className={styles['create-category-header-button-default']}
              onClick={onNextClickHandler}
              form="createCategory"
            >
              Next
              <ArrowRightIcon />
            </Button>
          )}
          {currentStep === 1 && (
            <Button
              type="primary"
              loading={isLoading}
              className={styles['create-category-header-button-primary']}
              onClick={handleCreateCategory}
            >
              {isEditing ? 'Save' : 'Create'}
              <CheckMarkIcon />
            </Button>
          )}
        </header>
        <Divider />
        <div className={styles['create-category-content']}>
          <div className={styles['create-category-content-stepper-wrapper']}>
            <Steps
              className={styles['create-category-content-stepper']}
              direction="vertical"
              current={currentStep}
              items={items}
            />
          </div>
          <Divider
            className={styles['create-category-content-divider']}
            type="vertical"
          />
          <div className={styles['create-category-content-steps']}>
            {steps[currentStep].content}
          </div>
        </div>
      </div>
    </Form>
  );
};

export const CreateCategory = withCreateCategoryContext(CreateCategoryInner);
