import { useEffect, useRef, useState } from 'react';
import { App, Button, Dropdown, Typography } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query as fbQuery,
  writeBatch,
  setDoc,
} from 'firebase/firestore';
import { db } from 'api/firebaseConfig';
import { AddQueryModal } from 'components/AddQueryModal/AddQueryModal';
import { deleteCollection } from 'common/helpers/deleteCollection';
import KebabIcon from 'common/icons/Kebab';
import { Collections } from 'common/constants';
import {
  Category as CategoryType,
  Query as QueryType,
  Question,
  Subcategory as SubcategoryType,
} from 'common/types/Queries';
import { QueryData } from 'pages/CreateCategory/CreateCategoryContext';

import { useLanguage } from 'common/helpers';
import styles from './Query.module.scss';
import Segment from '../../../../common/icons/Segment';

export const Query = ({
  category,
  categories,
  subcategory,
  subcategories,
  query,
  id,
  order,
  length,
}: {
  category: CategoryType;
  categories?: CategoryType[];
  subcategory?: SubcategoryType;
  subcategories?: SubcategoryType[];
  subcategoryId?: string;
  query: QueryType;
  id?: UniqueIdentifier;
  order?: number;
  length?: number;
  language: string;
}) => {
  const { notification } = App.useApp();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [open, setOpen] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id as UniqueIdentifier });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const toggleModal = () => {
    setOpen(!open);
  };
  const { language } = useLanguage();
  useEffect(() => {
    const questionsRef = subcategory
      ? collection(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id,
          Collections.Subcategories,
          subcategory.id,
          Collections.Queries,
          query.id,
          Collections.Questions
        )
      : collection(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id,
          Collections.Queries,
          query.id,
          Collections.Questions
        );

    const unsubscribe = onSnapshot(fbQuery(questionsRef), (snapshot) => {
      const newItems = snapshot.docs
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Question)
        )
        .sort((a, b) => (a.key < b.key ? -1 : 1));
      setQuestions(newItems);
    });

    return () => unsubscribe();
  }, [language]);

  const handleDelete = async () => {
    const queryDocPath = [category.id]
      .concat(subcategory ? [Collections.Subcategories, subcategory.id] : [])
      .concat([Collections.Queries, query.id]);
    const questionsPath = queryDocPath.concat([Collections.Questions]);
    if (length && length > 1) {
      Promise.all([
        deleteCollection(db, [
          Collections.Locales,
          language,
          Collections.Categories,
          ...questionsPath,
        ]),
        deleteDoc(
          doc(
            db,
            Collections.Locales,
            language,
            Collections.Categories,
            ...queryDocPath
          )
        ),
      ])
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Query was deleted successfully',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Query was not deleted',
          });
        });
    } else {
      notification.warning({
        message: 'Warning',
        description: 'Category should have at least 1 query',
      });
    }
  };

  const onEdit = () => {
    setOpen(true);
  };

  const handleEdit = async (queryData: QueryData) => {
    const queryDocPath = [category.id]
      .concat(subcategory ? [Collections.Subcategories, subcategory.id] : [])
      .concat([Collections.Queries, query.id]);
    const questionsPath = queryDocPath.concat([Collections.Questions]);

    const batch = writeBatch(db);

    batch.update(
      doc(
        db,
        Collections.Locales,
        language,
        Collections.Categories,
        ...queryDocPath
      ),
      {
        name: queryData.name,
        prompt: queryData.prompt,
      }
    );

    queryData.questions?.forEach((question) => {
      batch.set(
        doc(
          collection(
            db,
            Collections.Locales,
            language,
            Collections.Categories,
            ...questionsPath
          )
        ),
        {
          key: question.key,
          question: question.question,
        }
      );
    });

    try {
      await deleteCollection(db, [
        Collections.Locales,
        language,
        Collections.Categories,
        ...questionsPath,
      ]);
      await batch.commit();
      notification.success({
        message: 'Success',
        description: 'Query was updated successfully',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Query was not updated',
      });
    }
  };

  const queryData = {
    name: query.name,
    prompt: query.prompt,
    questions: questions.map(({ key, question }) => ({
      key,
      question,
    })),
  };
  const handleMoveQuery = async (
    currentCategoryId: string,
    newCategoryId: string,
    currentSubcategoryId?: string,
    newSubcategoryId?: string | null
  ) => {
    try {
      const queryRef = doc(
        db,
        Collections.Locales,
        language,
        Collections.Categories,
        newCategoryId,
        ...(newSubcategoryId
          ? [Collections.Subcategories, newSubcategoryId]
          : []),
        Collections.Queries,
        query.id
      );
      if (length && length > 1) {
        await setDoc(queryRef, { ...query });
      } else {
        notification.error({
          message: 'Error',
          description: 'At least should be one query',
        });
      }

      if (length && length > 1) {
        await deleteDoc(
          doc(
            db,
            Collections.Locales,
            language,
            Collections.Categories,
            currentCategoryId,
            ...(currentSubcategoryId
              ? [Collections.Subcategories, currentSubcategoryId]
              : []),
            Collections.Queries,
            query.id
          )
        );
      }
      if (length && length > 1)
        notification.success({
          message: 'Success',
          description: 'Query moved successfully',
        });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to move query',
      });
    }
  };
  const subcategoryClickedRef = useRef(false);
  const handleCategoryClick = (categoryId: string) => {
    if (!subcategoryClickedRef.current) {
      handleMoveQuery(category.id, categoryId, subcategory?.id, null);
    }
    subcategoryClickedRef.current = false;
  };

  const handleSubcategoryClick = (
    categoryId: string,
    subcategoryId: string
  ) => {
    subcategoryClickedRef.current = true;
    handleMoveQuery(category.id, categoryId, subcategory?.id, subcategoryId);
  };
  const list = categories
    ?.map((categoryEl) => {
      let filteredSubcategories = subcategories
        ?.filter((subEl) => subEl.categoryId === categoryEl.id)
        .map((subEl) => ({
          key: subEl.id,
          label: subEl.name,
          onClick: () => handleSubcategoryClick(categoryEl.id, subEl.id),
        }));
      const isCurrentCategory = categoryEl.id === category.id;
      const isSingleSubcategory = filteredSubcategories?.length === 1;
      if (isCurrentCategory) {
        filteredSubcategories = filteredSubcategories?.filter(
          (subItem) => subItem.key !== subcategory?.id
        );
      }

      if (
        (isCurrentCategory && !subcategory) ||
        (isCurrentCategory && isSingleSubcategory && subcategory)
      ) {
        return null;
      }

      return {
        key: categoryEl.id,
        label: categoryEl.name,
        onClick: filteredSubcategories?.length
          ? undefined
          : () => handleCategoryClick(categoryEl.id),
        children: filteredSubcategories?.length
          ? filteredSubcategories
          : undefined,
      };
    })
    .filter(Boolean);
  return (
    <div ref={setNodeRef} style={style} key={order}>
      <div className={styles['queries-content']}>
        <div className={styles['queries-content-header']}>
          <div className={styles['queries-content-header-box']}>
            <Segment {...listeners} {...attributes} />
            <Typography className={styles['queries-content-header-title']}>
              {query.name}
            </Typography>
          </div>
          <Dropdown
            placement="bottomRight"
            menu={{
              items: [
                { label: 'Edit', onClick: onEdit, key: 'edit' },
                { label: 'Delete', onClick: handleDelete, key: 'delete' },
                {
                  key: 'move',
                  label: 'Move to another category',
                  children: list,
                },
              ],
            }}
            trigger={['click']}
            overlayStyle={{ minWidth: '160px', padding: '8px 0' }}
          >
            <Button
              icon={<KebabIcon />}
              className={styles['queries-content-header-menu']}
            />
          </Dropdown>
          <AddQueryModal
            open={open}
            toggleModal={toggleModal}
            onCreate={handleEdit}
            initialValues={queryData}
          />
        </div>
        {questions && !!questions.length && (
          <div className={styles['queries-content-questions']}>
            <Typography
              className={`${styles['queries-content-tag']} ${styles['queries-content-questions-tag']}`}
            >
              Questions
            </Typography>
            <div className={styles['queries-content-questions-list']}>
              {questions.map(({ key, question }) => (
                <Typography.Paragraph
                  key={key}
                  className={styles['queries-content-questions-message']}
                >
                  <strong
                    className={styles['queries-content-questions-message-key']}
                  >
                    &lt;{key}&gt;
                  </strong>{' '}
                  {question}
                </Typography.Paragraph>
              ))}
            </div>
          </div>
        )}
        <div className={styles['queries-content-prompt']}>
          <Typography
            className={`${styles['queries-content-tag']} ${styles['queries-content-prompt-tag']}`}
          >
            prompt
          </Typography>
          <Typography className={styles['queries-content-prompt-message']}>
            {query.prompt}
          </Typography>
        </div>
      </div>
    </div>
  );
};
