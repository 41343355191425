import { auth } from 'api/firebaseConfig';
import { ROUTES } from 'common/constants';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const ProtectedRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate(ROUTES.LOGIN);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return <Outlet />;
};
