import { useEffect, useState } from 'react';
import { Button, Select, Space, Typography } from 'antd';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from 'api/firebaseConfig';
import { useLanguage } from 'common/helpers';
import { Collections, ROUTES } from 'common/constants';
import { Category as CategoryType } from 'common/types/Queries';
import { languageOptions } from './config';
import { Category } from './Category';
import styles from './Categories.module.scss';

export const Categories = () => {
  const { Option } = Select;
  const navigate = useNavigate();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const { language, setLanguage } = useLanguage();
  const handleLanguageChange = (newLanguage: string) => {
    if (newLanguage) {
      setLanguage(newLanguage);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, Collections.Locales, language, Collections.Categories),
        orderBy('timestamp', 'desc')
      ),
      (snapshot) => {
        const newItems = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as CategoryType)
        );

        setCategories(newItems);
      }
    );

    return () => unsubscribe();
  }, [language]);

  const onCreateCategoryClickHandler = () => {
    navigate(ROUTES.CREATE_CATEGORY);
  };
  return (
    <div className={styles.categories}>
      <div className={styles['categories-header']}>
        <div className={styles['categories-header-box']}>
          <Typography.Title className={styles['categories-header-title']}>
            Categories
          </Typography.Title>
          <Select
            defaultValue={language}
            style={{ width: 200 }}
            onChange={handleLanguageChange}
          >
            {languageOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
        <Button
          type="primary"
          className={styles['categories-header-button']}
          onClick={onCreateCategoryClickHandler}
        >
          Create Category
        </Button>
      </div>
      <Space
        className={styles['categories-content']}
        direction="vertical"
        size={8}
      >
        {!categories?.length && (
          <div className={styles['categories-no-content']}>Not created yet</div>
        )}
        {categories.map((category, index) => (
          <Category
            key={category.id}
            category={category}
            defaultOpen={index === 0}
            categories={categories}
          />
        ))}
      </Space>
    </div>
  );
};
