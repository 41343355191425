const CheckMarkIcon = ({ width = 20, height = 20, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7.5 13.475-2.892-2.892a.83.83 0 1 0-1.175 1.175l3.484 3.484a.83.83 0 0 0 1.175 0l8.816-8.817a.83.83 0 1 0-1.175-1.175L7.5 13.475Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CheckMarkIcon;
