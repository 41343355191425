import { Outlet } from 'react-router-dom';
import { Header } from './components/Header/Header';

import styles from './Main.module.scss';

export const MainLayout = () => {
  return (
    <section className={styles['main-layout']}>
      <div className={styles['main-layout-container']}>
        <Header />
        <div className={styles['main-layout-content']}>
          <Outlet />
        </div>
      </div>
    </section>
  );
};
