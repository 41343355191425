import {
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  ClipboardEvent,
} from 'react';
import { Space } from 'antd';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { Chip } from 'common/Chip';
import { TextCount } from 'components/AddQueryModal/TextCount/TextCount';
import { useQuestionsContext } from '../QuestionsContext';

import styles from './TextareaWithChips.module.scss';

const createChip = (id: string, index: number) => {
  return `<section class="chips-textarea-chip" data-question-id="${id}" contenteditable="false"><span>Question ${
    index + 1
  }</span> <div class="chips-textarea-chip-delete" onclick="event.target.parentElement.remove()"></div></section>&nbsp;`;
};

const regex =
  /<section.*?data-question-id=".*?><span>([\w\s]+)<\/span>.*?<\/div><\/section>/gim;

const deserialize = (html: string) => {
  return html.replace(regex, '<$1>').replace(/&nbsp;/gim, ' ');
};

const isFirefox = () => navigator.userAgent.includes('Firefox');

export const TextareaWithChips = ({
  onChange,
  initialValue,
}: {
  onChange: (value: string) => void;
  initialValue?: string;
}) => {
  const [questions] = useQuestionsContext();
  const [updatedText, setUpdatedText] = useState<string>();

  const processInitialValue = (initialValue?: string) => {
    if (!initialValue) {
      return '';
    }

    const regex = /<Question (\d+)>/gim;
    const regexNewLine = /\r?\n/gim;

    let text = initialValue;

    initialValue.match(regex)?.forEach((match) => {
      const index = match.match(/<Question (\d+)>/)?.[1];

      const question = questions[Number(index) - 1];

      if (!question) {
        return;
      }

      text = text.replace(match, createChip(question.id, Number(index) - 1));
    });

    text = text.trim();
    const newLineChunks = text.split(regexNewLine);

    if (newLineChunks.length > 0) {
      let multilineText = '<div>';
      newLineChunks.forEach((newLineChunk) => {
        multilineText += `${newLineChunk}</div>${
          newLineChunk.length ? '' : '<br>'
        }<div>`;
      });
      multilineText += '</div>';
      return multilineText.replaceAll('<div></div>', '');
    }

    return text;
  };

  const text = useRef(processInitialValue(initialValue));
  const innerRef = useRef<HTMLElement>(null);

  const handleChange = (event: ContentEditableEvent) => {
    text.current = event.target.value;
    onChange(deserialize(text.current));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && isFirefox()) {
      event.preventDefault();
      event.stopPropagation();
      text.current += '<br>\n';

      innerRef.current?.focus();
      onChange(deserialize(text.current));
    }
    if (event.key === ' ' && isFirefox()) {
      event.preventDefault();
      event.stopPropagation();
      text.current += ' ';

      innerRef.current?.focus();
      onChange(deserialize(text.current));
    }
  };

  const insertQuestionTag = (id: string, index: number) => {
    const contentEditable = innerRef.current;
    let { current } = text;
    if (!contentEditable) return;
    contentEditable.focus();
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      let currentNode: HTMLElement | null =
        range.commonAncestorContainer as HTMLElement;
      let isCursorInsideChip = false;
      while (currentNode) {
        if (
          currentNode.nodeName === 'BUTTON' ||
          currentNode.classList?.contains('ant-space-item')
        ) {
          isCursorInsideChip = true;
          break;
        }
        currentNode = currentNode.parentElement;
      }

      if (!isCursorInsideChip) {
        const buttonHtml = createChip(id, index);
        const fragment = range.createContextualFragment(buttonHtml);
        range.deleteContents();
        range.insertNode(fragment);

        if (fragment.lastChild && fragment.lastChild instanceof Node) {
          range.setStartAfter(fragment.lastChild);
        } else {
          range.collapse(false);
        }

        current = contentEditable.innerHTML;
        text.current = current;
        onChange(deserialize(current));
      }
    }
    contentEditable.focus();
  };

  useEffect(() => {
    let updatedText = text.current;

    text.current.match(regex)?.forEach((match) => {
      const id = match.match(/data-question-id="([\w-]+)"/)?.[1];

      const newIndex = questions.findIndex((question) => question.id === id);

      if (newIndex === -1) {
        updatedText = updatedText.replaceAll(match, '');
      }

      const updatedMatch = match.replace(
        /Question (\d+)/,
        `Question ${newIndex + 1}`
      );

      updatedText = updatedText.replace(match, updatedMatch);
    });

    setUpdatedText(updatedText);
  }, [questions.length]);

  useEffect(() => {
    if (!updatedText || updatedText === text.current) {
      return;
    }

    text.current = updatedText;
    setUpdatedText(undefined);
    onChange(deserialize(text.current));
  }, [updatedText]);
  const generateUniqueId = () => {
    const timestamp = new Date().getTime().toString(36);
    const randomPart = Math.random().toString(36).substr(2, 5);
    return `${timestamp}-${randomPart}`;
  };
  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const textFromClipboard = event.clipboardData.getData('text/plain').trim();

    const updatedText = textFromClipboard.replace(
      /<Question (\d+)>/g,
      (_match, number) => {
        const id = generateUniqueId();
        return createChip(id, parseInt(number, 10) - 1);
      }
    );

    text.current += updatedText;

    if (innerRef.current) {
      innerRef.current.textContent = text.current;
    }

    innerRef.current?.focus();
    onChange(deserialize(text.current));
  };

  return (
    <>
      <Space className={styles.rowBtn} size={16} wrap>
        {questions.map(({ id }, index) => (
          <Chip
            key={id}
            text={`Question ${index + 1}`}
            onClick={() => {
              insertQuestionTag(id, index);
            }}
          />
        ))}
      </Space>
      <ContentEditable
        className={styles['chips-textarea']}
        html={text.current}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        innerRef={innerRef}
        id="contentEditable"
      />
      <TextCount text={innerRef.current?.textContent} />
    </>
  );
};
