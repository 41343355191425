const KebabIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00033 5.33317C8.73366 5.33317 9.33366 4.73317 9.33366 3.99984C9.33366 3.2665 8.73366 2.6665 8.00033 2.6665C7.26699 2.6665 6.66699 3.2665 6.66699 3.99984C6.66699 4.73317 7.26699 5.33317 8.00033 5.33317ZM8.00033 6.6665C7.26699 6.6665 6.66699 7.2665 6.66699 7.99984C6.66699 8.73317 7.26699 9.33317 8.00033 9.33317C8.73366 9.33317 9.33366 8.73317 9.33366 7.99984C9.33366 7.2665 8.73366 6.6665 8.00033 6.6665ZM8.00033 10.6665C7.26699 10.6665 6.66699 11.2665 6.66699 11.9998C6.66699 12.7332 7.26699 13.3332 8.00033 13.3332C8.73366 13.3332 9.33366 12.7332 9.33366 11.9998C9.33366 11.2665 8.73366 10.6665 8.00033 10.6665Z"
      fill="#969696"
    />
  </svg>
);
export default KebabIcon;
