export enum ROUTES {
  EDIT_CATEGORY = '/edit-category/:id',
  CREATE_CATEGORY = '/create-category',
  LOGIN = '/login',
  HOME = '/',
}

export enum Collections {
  Categories = 'categories',
  Subcategories = 'subcategories',
  Queries = 'queries',
  Questions = 'questions',
  Locales = 'locales',
}
