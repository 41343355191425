import { QueryData } from 'pages/CreateCategory/CreateCategoryContext';
import {
  useState,
  FC,
  useMemo,
  Dispatch,
  SetStateAction,
  createContext,
  ReactNode,
  useContext,
} from 'react';
import { v4 as uuid } from 'uuid';

export type QuestionInitState = Array<{
  id: string;
  question: string;
}>;

export type QuestionContext = [
  QuestionInitState,
  Dispatch<SetStateAction<QuestionInitState>>
];

interface QuestionsProviderProps {
  children?: ReactNode;
  initialValues?: QueryData;
}

export const QuestionsContext = createContext<QuestionContext>([[], () => {}]);

export const useQuestionsContext = () => useContext(QuestionsContext);

export const QuestionsProvider: FC<QuestionsProviderProps> = ({
  children,
  initialValues,
}) => {
  const [questions, setQuestions] = useState<QuestionInitState>(
    initialValues?.questions?.map((question) => {
      return {
        id: question.id || uuid(),
        question: question.question,
      };
    }) || []
  );

  const contextValue: QuestionContext = useMemo(
    () => [questions, setQuestions],
    [questions]
  );

  return (
    <QuestionsContext.Provider value={contextValue}>
      {children}
    </QuestionsContext.Provider>
  );
};
