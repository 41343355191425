import { Checkbox, Dropdown, Form, Input, Space, Typography } from 'antd';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react/';
import { DownOutlined } from '@ant-design/icons';

import { useCreateCategoryContext } from 'pages/CreateCategory/CreateCategoryContext';
import { customValidation } from 'common/helpers/customValidation';

import styles from './CategorySettingsStep.module.scss';

const CATEGORY_MAX_CHARACTERS = 50;

export const CategorySettingsStep = () => {
  const { isEditing } = useCreateCategoryContext();
  const form = Form.useFormInstance();
  const baseValidation = [
    {
      required: true,
      message: 'Please input a category name!',
    },
    {
      max: CATEGORY_MAX_CHARACTERS,
      message: `Maximum is ${CATEGORY_MAX_CHARACTERS} characters`,
    },
  ];
  const customValidationRule = isEditing
    ? baseValidation
    : [...baseValidation, customValidation.categoryName];

  const emojiSelectHandler = ({ emoji }: EmojiClickData) => {
    form.setFieldValue('emoji', emoji);
  };

  return (
    <Space className={styles.step} size={24} direction="vertical">
      <Typography className={styles['step-title']}>
        Category Settings
      </Typography>
      <Space className={styles.step} size={0} direction="vertical">
        <Space size={24} className={styles['step-item-wrapper']}>
          <Form.Item
            label="Category Name"
            name="categoryName"
            rules={customValidationRule}
          >
            <Input className={styles['step-input']} placeholder="Input Text" />
          </Form.Item>
          <div className={styles.emoji}>
            <Form.Item
              label="Emoji"
              name="emoji"
              rules={[{ required: true, message: 'Please select an emoji!' }]}
            >
              <Input className={styles['emoji-input']} />
            </Form.Item>
            <Dropdown
              /* eslint-disable-next-line react/no-unstable-nested-components */
              dropdownRender={() => (
                <EmojiPicker onEmojiClick={emojiSelectHandler} />
              )}
              className={styles['emoji-dropdown']}
              trigger={['click']}
            >
              <DownOutlined />
            </Dropdown>
          </div>
        </Space>
        <Space size={0} direction="vertical">
          <Form.Item
            className={styles['step-form-item-checkbox']}
            name="noSubcategory"
            valuePropName="checked"
          >
            <Checkbox>No Subcategory</Checkbox>
          </Form.Item>
          <Typography className={styles['step-form-item-checkbox-description']}>
            If you select this option then second step will be skipped
          </Typography>
        </Space>
      </Space>
    </Space>
  );
};
