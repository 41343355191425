import { useState } from 'react';
import { Button, Form, Popconfirm, Typography } from 'antd';
import EditIcon from 'common/icons/Edit';
import DeleteIcon from 'common/icons/Delete';
import { useCreateCategoryContext } from 'pages/CreateCategory/CreateCategoryContext';
import { Queries } from '../Queries/Queries';

import styles from './Subcategories.module.scss';
import { SubcategoryNameForm } from '../SubcategoryNameForm/SubcategoryNameForm';

export const Subcategories = () => {
  const [isEditing, setIsEditing] = useState<Record<string, boolean>>({});
  const { category, setCategory } = useCreateCategoryContext();
  const form = Form.useFormInstance();

  const getDeleteSubcategoryHandler = (index: number) => () => {
    setCategory((prev) => ({
      ...prev,
      subcategories: prev.subcategories?.filter((_, i) => i !== index),
    }));
  };

  const enableEditing = (index: number) => {
    form.setFieldValue(
      `updateSubcategory-${index}`,
      category.subcategories?.[index].name
    );
    setIsEditing({ [index]: true });
  };

  const getEnableEditingHandler = (index: number) => () => {
    enableEditing(index);
  };

  const disableEditing = (index: number) => {
    form.resetFields([`updateSubcategory-${index}`]);
    setIsEditing({ [index]: false });
  };

  const getDisableEditingHandler = (index: number) => () => {
    disableEditing(index);
  };

  const getSubcategoryUpdateHandler = (index: number) => () => {
    form
      .validateFields()
      .then((values) => {
        setCategory((prev) => ({
          ...prev,
          subcategories: prev.subcategories?.map((subcategory, i) => {
            if (i === index) {
              return {
                ...subcategory,
                name: values[`updateSubcategory-${index}`],
              };
            }
            return subcategory;
          }),
        }));
        form.resetFields([`updateSubcategory-${index}`]);
        disableEditing(index);
      })
      .catch(() => undefined);
  };

  if (!category.subcategories?.length) {
    return null;
  }

  return (
    <div className={styles.subcategories}>
      {category.subcategories?.map((subcategory, index) => (
        <div
          key={`${subcategory.name}-${index}`}
          className={styles['subcategories-item']}
        >
          {isEditing[index] ? (
            <SubcategoryNameForm
              onCancel={getDisableEditingHandler(index)}
              onCreate={getSubcategoryUpdateHandler(index)}
              name={`updateSubcategory-${index}`}
            />
          ) : (
            <div className={styles['subcategories-item-header']}>
              <Typography className={styles['subcategories-item-header-name']}>
                {subcategory.name}
              </Typography>
              <div className={styles['subcategories-item-header-actions']}>
                <Button
                  icon={<EditIcon />}
                  className={styles['subcategories-item-header-actions-button']}
                  onClick={getEnableEditingHandler(index)}
                />
                <Popconfirm
                  title="Delete the subcategory"
                  description="Are you sure to delete this subcategory?"
                  onConfirm={getDeleteSubcategoryHandler(index)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    icon={<DeleteIcon />}
                    className={
                      styles['subcategories-item-header-actions-button']
                    }
                  />
                </Popconfirm>
              </div>
            </div>
          )}

          <Queries subcategoryIndex={index} />
        </div>
      ))}
    </div>
  );
};
