import { Outlet } from 'react-router-dom';

import styles from './Auth.module.scss';

export const AuthLayout = () => {
  return (
    <section className={styles['auth-layout']}>
      <div className={styles['auth-layout-container']}>
        <Outlet />
      </div>
    </section>
  );
};
