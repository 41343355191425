import React from 'react';
import { App, ConfigProvider } from 'antd';

import './AntdOverrides.scss';

export const AntdThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <App>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              fontSize: 16,
              lineHeight: 1.5,
            },
            Input: {
              controlHeight: 52,
              lineHeight: 1.6,
              fontSize: 20,
              paddingSM: 16,
            },
            Select: {
              controlHeight: 52,
              lineHeight: 1.6,
              fontSize: 20,
              paddingSM: 16,
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </App>
  );
};
