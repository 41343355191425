import {
  ReactNode,
  createContext,
  useContext,
  useState,
  FC,
  useMemo,
} from 'react';

interface LanguageContextType {
  language: string;
  setLanguage: (newLanguage: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>({
  language: 'US',
  setLanguage: () => {},
});

export const LanguageProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState('US');
  const contextValue = useMemo(() => ({ language, setLanguage }), [language]);

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
