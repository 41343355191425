import { SVGProps } from 'react';

const Segment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 18H21V16H9V18ZM3 6V8H21V6H3ZM9 13H21V11H9V13Z" fill="#969696" />
  </svg>
);
export default Segment;
