import { Outlet } from 'react-router-dom';

import styles from './CreateCategory.module.scss';

export const CreateCategoryLayout = () => {
  return (
    <section className={styles['create-category-layout']}>
      <div className={styles['create-category-container']}>
        <Outlet />
      </div>
    </section>
  );
};
