import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Form,
  Button,
  Input,
  message,
  Space,
  Typography,
  Popconfirm,
} from 'antd';

import DeleteIcon from 'common/icons/Delete';
import { QueryData } from 'pages/CreateCategory/CreateCategoryContext';
import { TextCount } from 'components/AddQueryModal/TextCount/TextCount';
import { TextareaWithChips } from './TextareaWithChips/TextareaWithChips';
import { useQuestionsContext } from './QuestionsContext';

import styles from './AddQueryModal.module.scss';
import { customValidation } from '../../common/helpers/customValidation';

enum FieldsName {
  prompt = 'prompt',
  name = 'name',
}

interface AddQueryFromData {
  name: string;
  prompt: string;
}

interface AddQueryFormProps {
  toggleModal: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onCreate?: (queryData: QueryData) => void | Promise<void>;
  name: string;
  initialValues?: QueryData;
}

export const cleanPromptFromHTML = (htmlString: string) => {
  return htmlString
    .replaceAll(/<div>(<br\s*\/?>\s*)?<\/div>/g, '')
    .replaceAll(/<p[^>]*>/gm, '')
    .replaceAll(/<span[^>]*>/gm, '')
    .replaceAll('<div>', '\n')
    .replaceAll(/<\/\w+>/gm, '')
    .replaceAll(/<br\s?\/?>/gi, '\n')
    .replaceAll('&amp;', '&')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>');
};

export const QUESTION_MAX_CHARACTERS = 2500;
export const QUERY_MAX_CHARACTERS = 200;

export const AddQueryForm: FC<AddQueryFormProps> = ({
  setLoading,
  toggleModal,
  onCreate,
  name,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [questions, setQuestions] = useQuestionsContext();

  const onFinish = async ({ prompt, name, ...questions }: AddQueryFromData) => {
    setLoading(true);

    try {
      const questionsData = Object.values(questions).map((question, index) => ({
        key: `Question ${index + 1}`,
        question: question as string,
      }));

      let promptData = prompt;
      Object.keys(questions).forEach((key, index) => {
        promptData = promptData.replace(
          new RegExp(`<${key}>`, 'gi'),
          `<Question ${index + 1}>`
        );
      });

      promptData = cleanPromptFromHTML(promptData);

      await onCreate?.({ name, prompt: promptData, questions: questionsData });

      form.resetFields();
    } catch (error: any) {
      message.error(error.message as string);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  const addQuestionHandler = () => {
    setQuestions([
      ...questions,
      {
        id: uuidv4(),
        question: '',
      },
    ]);
  };

  const questionChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestions(
      questions.map((item) =>
        item.id === e.currentTarget.name
          ? { ...item, question: e.currentTarget.value }
          : item
      )
    );
  };

  const removeQuestionHandler = (id: string) => {
    setQuestions(questions.filter((item) => item.id !== id));
  };

  const updatePrompt = (promptValue: string) => {
    form.setFieldValue(FieldsName.prompt, promptValue);
    form.validateFields([FieldsName.prompt]);
  };

  return (
    <Form
      form={form}
      name={name}
      onFinish={onFinish}
      requiredMark={false}
      className={styles.form}
      initialValues={{
        [FieldsName.name]: initialValues?.name,
        [FieldsName.prompt]: initialValues?.prompt,
        ...questions?.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: item.question,
          }),
          {}
        ),
      }}
    >
      <Form.Item
        label="Query name"
        name={FieldsName.name}
        rules={[
          { required: true, message: 'Please input query name!' },
          {
            max: QUERY_MAX_CHARACTERS,
            message: `Maximum is ${QUERY_MAX_CHARACTERS} characters`,
          },
        ]}
      >
        <Input type="text" placeholder="Provide low cost start-up ideas" />
      </Form.Item>

      <Space className={styles['section-title']}>
        <Typography.Title level={5}>Questions</Typography.Title>
        <Button type="primary" ghost onClick={addQuestionHandler}>
          Add question
        </Button>
      </Space>

      <div className={styles['question-container']}>
        {questions.map((question, index) => (
          <div className={styles.question} key={question.id}>
            <Typography.Paragraph
              className={styles['question-label']}
            >{`Question ${index + 1}`}</Typography.Paragraph>
            <div className={styles['question-text']}>
              <Form.Item
                name={question.id}
                rules={[
                  { required: true, message: 'Please write a question' },
                  {
                    max: QUESTION_MAX_CHARACTERS,
                    message: `Maximum is ${QUESTION_MAX_CHARACTERS} characters`,
                  },
                ]}
                className={styles['question-form-item']}
              >
                <Input.TextArea
                  rows={3}
                  onChange={questionChangeHandler}
                  placeholder="Write a question"
                />
              </Form.Item>
              <TextCount text={form.getFieldValue(question.id)} />
            </div>
            <Popconfirm
              title={`Are you sure you want to delete this Question ${
                form.getFieldValue(question.id) || index + 1
              }?`}
              description="This action can’t be undone."
              onConfirm={() => removeQuestionHandler(question.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={styles['question-icon']}
                icon={<DeleteIcon />}
              />
            </Popconfirm>
          </div>
        ))}
      </div>

      <Space
        className={`${styles['section-title']} ${styles['section-title--margin-top']}`}
      >
        <Typography.Title level={5}>Prompt</Typography.Title>
      </Space>

      <Form.Item
        name={FieldsName.prompt}
        rules={[
          { required: true, message: 'Please write a prompt' },
          customValidation.promptMaxLength,
        ]}
      >
        <TextareaWithChips
          onChange={updatePrompt}
          initialValue={initialValues?.prompt}
        />
      </Form.Item>
    </Form>
  );
};
