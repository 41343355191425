export const languageOptions = [
  { value: 'US', label: 'English' },
  { value: 'NL', label: 'Dutch' },
  { value: 'FR', label: 'French' },
  { value: 'DE', label: 'German' },
  { value: 'JP', label: 'Japanese' },
  { value: 'PT', label: 'Portuguese' },
  { value: 'CN', label: 'Simplified Chinese' },
  { value: 'TW', label: 'Traditional Chinese' },
  { value: 'ES', label: 'Spanish' },
  { value: 'IT', label: 'Italian' },
  { value: 'KP', label: 'Korean' },
  { value: 'TR', label: 'Turkish' },
];
