import {
  Firestore,
  Query,
  collection,
  getDocs,
  limit,
  query,
  writeBatch,
} from 'firebase/firestore';

export const deleteQueryBatch = async (
  db: Firestore,
  queryRef: Query,
  resolve: (value: unknown) => void,
  subcollectionPath?: string[]
) => {
  const snapshot = await getDocs(queryRef);

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    resolve(true);
    return;
  }

  if (subcollectionPath?.length) {
    await Promise.all(
      snapshot.docs.map(async (doc) => {
        const [subcollectionName, ...rest] = subcollectionPath;

        const queryRef = query(
          collection(doc.ref, subcollectionName),
          limit(batchSize)
        );

        return new Promise((resolve, reject) => {
          deleteQueryBatch(db, queryRef, resolve, rest).catch(reject);
        });
      })
    );
  }

  const batch = writeBatch(db);
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  setTimeout(() => {
    deleteQueryBatch(db, queryRef, resolve);
  }, 0);
};

const batchSize = 100;

export const deleteCollection = async (
  db: Firestore,
  collectionPath: string[],
  subcollectionPath?: string[]
) => {
  const [path, ...rest] = collectionPath;
  const collectionRef = collection(db, path, ...rest);
  const queryRef = query(collectionRef, limit(batchSize));

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, queryRef, resolve, subcollectionPath).catch(reject);
  });
};
