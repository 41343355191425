import { useState } from 'react';
import { Button, Form, Input, Space, Typography, message } from 'antd';
import LogoIcon from 'common/icons/Logo';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/constants';
import {
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { auth } from 'api/firebaseConfig';

import styles from './Login.module.scss';

interface LoginFromData {
  email: string;
  password: string;
}

export const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onFinish = async ({ email, password }: LoginFromData) => {
    try {
      setLoading(true);
      await setPersistence(auth, browserSessionPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      if (!idTokenResult?.claims.admin) {
        await signOut(auth);
        throw new Error('You are not an admin!');
      }

      navigate(ROUTES.HOME);
    } catch (error: any) {
      message.error('Email or password are incorrect' as string);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={styles.login}>
      <div className={styles['login-form-wrapper']}>
        <Space className={styles['login-form']} direction="vertical" size={40}>
          <div className={styles['login-form-header']}>
            <Typography.Title>Login</Typography.Title>
            <LogoIcon />
          </div>
          <Form
            name="login"
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input type="email" placeholder="mail@mail.com" />
            </Form.Item>
            <Form.Item
              className={styles['login-form-password']}
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password placeholder="Enter a password" />
            </Form.Item>
          </Form>
          <Button
            className={styles['login-form-button']}
            htmlType="submit"
            form="login"
            type="primary"
            loading={loading}
          >
            Login
          </Button>
        </Space>
      </div>
    </section>
  );
};
