import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { AntdThemeProvider } from 'components/AntdThemeProvider';
import './index.scss';
import { LanguageProvider } from 'common/helpers';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <AntdThemeProvider>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </AntdThemeProvider>
    </Router>
  </React.StrictMode>
);
