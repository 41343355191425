import React, { useContext, useEffect, useMemo } from 'react';
import { App } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Category, Query, Question, Subcategory } from 'common/types/Queries';
import { Collections, ROUTES } from 'common/constants';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from 'api/firebaseConfig';
import { useLanguage } from 'common/helpers';

export type QuestionData = Omit<Question, 'id'> & {
  id?: string;
};
export type QueryData = Omit<Query, 'id'> & {
  id?: string;
  questions?: QuestionData[];
};
export type SubcategoryData = Omit<Subcategory, 'id'> & {
  id?: string;
  queries?: QueryData[];
};
export type CategoryData = Omit<Category, 'id'> & {
  id?: string;
  subcategories?: SubcategoryData[];
  queries?: QueryData[];
  hasSubcategories?: boolean;
};

export interface CreateCategoryContextState {
  category: CategoryData;
  setCategory: React.Dispatch<React.SetStateAction<CategoryData>>;
  isEditing?: boolean;
  isLoading?: boolean;
  categoryId?: string;
}

const defaultState: CreateCategoryContextState = {
  category: {
    name: 'empty',
    emoji: '',
  },
  setCategory: () => undefined,
};

export const CreateCategoryContext =
  React.createContext<CreateCategoryContextState>(defaultState);

export const useCreateCategoryContext = () => useContext(CreateCategoryContext);

export const CreateCategoryContextProvider = ({
  children,
  isEditing,
}: {
  children: React.ReactNode;
  isEditing?: boolean;
}) => {
  const { id: categoryId } = useParams<{ id: string }>();

  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState<boolean>(isEditing || false);
  const [category, setCategory] = React.useState<CategoryData>({
    name: '',
    emoji: '',
  });

  const { language } = useLanguage();

  useEffect(() => {
    if (!isEditing || !categoryId) {
      return;
    }
    const collectData = async () => {
      const localeRef = doc(db, Collections.Locales, language);
      const categoryRef = doc(localeRef, Collections.Categories, categoryId);
      const categoryDoc = await getDoc(categoryRef);
      const categoryData = categoryDoc.data() as Category;

      const subcategoriesRef = collection(
        categoryRef,
        Collections.Subcategories
      );
      const subcategories = await getDocs(subcategoriesRef);
      const subcategoriesData = await Promise.all(
        subcategories.docs.map((subcategory) => {
          const subcategoryData = subcategory.data() as Subcategory;
          const queriesRef = collection(subcategory.ref, Collections.Queries);

          return getDocs(queriesRef).then(async (queries) => ({
            ...subcategoryData,
            queries: await Promise.all(
              queries.docs.map((query) => {
                const queryData = query.data() as Query;
                const questionsRef = collection(
                  query.ref,
                  Collections.Questions
                );

                return getDocs(questionsRef).then((questions) => ({
                  ...queryData,
                  questions: questions.docs.map(
                    (question) =>
                      ({
                        ...question.data(),
                        id: question.id,
                      } as QuestionData)
                  ),
                }));
              })
            ),
          }));
        })
      );

      const queriesRef = collection(categoryRef, Collections.Queries);
      const queries = await getDocs(queriesRef);
      const queriesData = await Promise.all(
        queries.docs.map((query) => {
          const queryData = query.data() as Query;
          const questionsRef = collection(query.ref, Collections.Questions);
          return getDocs(questionsRef).then((questions) => ({
            ...queryData,
            questions: questions.docs.map(
              (question) =>
                ({
                  ...question.data(),
                  id: question.id,
                } as QuestionData)
            ),
          }));
        })
      );

      setCategory({
        ...categoryData,
        subcategories: subcategoriesData,
        queries: queriesData,
      });
    };

    setIsLoading(true);
    collectData()
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Something went wrong while fetching the category',
        });
        navigate(ROUTES.HOME);
      })
      .finally(() => setIsLoading(false));
  }, [categoryId, language]);

  const state = useMemo(
    () => ({
      category,
      setCategory,
      isEditing,
      isLoading,
      categoryId,
    }),
    [category, isEditing, isLoading, categoryId]
  );

  return (
    <CreateCategoryContext.Provider value={state}>
      {children}
    </CreateCategoryContext.Provider>
  );
};

export const withCreateCategoryContext =
  <P extends { isEditing?: boolean }>(
    Component: React.ComponentType<Omit<P, 'isEditing'>>
  ) =>
  ({ isEditing, ...props }: P) =>
    (
      <CreateCategoryContextProvider isEditing={isEditing}>
        <CreateCategoryContext.Consumer>
          {({ isLoading }) =>
            isLoading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  minHeight: '100vh',
                }}
              >
                <LoadingOutlined style={{ fontSize: '48px' }} />
              </div>
            ) : (
              <Component {...props} />
            )
          }
        </CreateCategoryContext.Consumer>
      </CreateCategoryContextProvider>
    );
