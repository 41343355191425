const LogoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={140} height={24} fill="none">
    <circle cx={117.098} cy={8.137} r={4.915} fill="#DA1823" />
    <path
      fill="currentColor"
      d="M127.112 18.935h-7.915l.013-.577c0-.377 0-.9-.027-1.573a38.846 38.846 0 0 0-.121-2.271h-7.136c-1.29 2.54-2.029 4.421-2.029 4.421h-7.902s2.312-4.73 5.174-9.42c3.373-5.362 6.787-9.475 6.787-9.475h9.219s1.639 4.193 2.768 9.475c.901 4.542 1.169 9.42 1.169 9.42Zm-7.861-8.412c-.484-3.79-1.452-6.115-1.452-6.115h-.322s-1.962 2.298-4.032 6.115h5.806ZM135.982 18.935h-7.566L132.434 0H140l-4.018 18.935ZM4.965 19.39H1.167V5.11h3.798v14.28ZM3.191 3.36C1.642 3.36.743 2.825.743 1.675.743.5 1.643 0 3.19 0c1.362 0 2.187.537 2.187 1.674 0 1.15-.875 1.687-2.187 1.687ZM35.318 19.402h-3.785v-7.82c0-1.4-.163-4.286-4.086-4.286-3.86 0-4.285 2.611-4.285 4.498v7.608h-3.823v-8.395c0-2.262-.812-3.71-3.986-3.71-3.335 0-4.172 2.186-4.31 3.935-.037.437-.05.824-.05 1.137v7.033H7.195V5.122h3.41l.35 2.861h.088c1.187-2.286 3.26-3.235 5.722-3.235 3.198 0 5.335 1.149 6.01 3.285h.05c1.211-2.348 3.423-3.285 5.984-3.285 5.435 0 6.509 3.248 6.509 6.996v7.658ZM40.883 24h-3.798V5.222h3.374l.437 2.449h.062c1.587-2.111 3.511-2.986 6.522-2.986 4.223 0 7.659 2.424 7.659 7.196 0 1.587-.076 2.511-.625 3.686-1.275 2.698-4.036 4.048-7.034 4.048-3.136 0-5.085-.925-6.547-3.336h-.05V24Zm5.223-6.821c2.623 0 5.222-1.325 5.222-4.598v-.775c0-3.31-2.824-4.672-5.235-4.672-2.536 0-5.235 1.1-5.235 4.697v.662c.038 2.3 1.087 3.624 2.687 4.223.8.313 1.649.463 2.56.463Z"
    />
    <path
      fill="currentColor"
      d="M61.478 19.765c-3.81 0-6.509-1.512-6.509-4.323 0-3.011 2.312-4.035 4.985-4.035h8.796l.012-.263c0-2.186-1.087-3.66-4.272-3.66h-8.746l.712-2.512 7.684.013c6.471.062 8.433 2.311 8.433 6.709l.012 7.683h-3.46l-.313-2.773h-.037c-1.187 2.224-4.01 3.16-7.297 3.16Zm1.425-2.3c2.936 0 5.86-1.099 5.86-4.11l.012-.787h-7.421c-1.937 0-2.649.875-2.649 2.312 0 1.524 1.312 2.586 4.198 2.586ZM90.72 19.415h-8.521c-5.61 0-8.958-2.374-8.958-7.246 0-2.549.6-4.31 2.299-5.672 1.524-1.225 3.748-1.837 6.659-1.837 7.346 0 8.907 4.898 8.907 7.234l-3.71.325.012-.787c0-2.1-1.924-4.236-5.21-4.236-3.21 0-5.222 1.375-5.222 4.56v.675c0 2.811 1.624 4.548 5.223 4.573l8.907-.025-.387 2.436Z"
    />
    <path
      fill="currentColor"
      d="M96.484 19.402c-3.535-.037-4.922-1.924-4.922-4.547V7.57H90.05V5.085h1.512l.35-2.624h3.46v2.624h5.085l-.412 2.486h-4.672v7.134c0 1.25.7 2.336 2.36 2.336h3.062v2.349l-4.31.012Z"
    />
    <path
      fill="#DA1823"
      d="M3.191 3.36C1.642 3.36.743 2.825.743 1.675.743.5 1.643 0 3.19 0c1.362 0 2.187.537 2.187 1.674 0 1.15-.875 1.687-2.187 1.687Z"
    />
  </svg>
);

export default LogoIcon;
