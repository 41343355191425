const ArrowRightIcon = ({ width = 20, height = 20, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.744 5.245a.83.83 0 0 0 0 1.175l3.233 3.233-3.233 3.233a.83.83 0 1 0 1.175 1.176l3.825-3.825a.83.83 0 0 0 0-1.176L8.919 5.236a.838.838 0 0 0-1.175.009Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ArrowRightIcon;
