import { FC } from 'react';
import { Typography } from 'antd';
import {
  cleanPromptFromHTML,
  QUESTION_MAX_CHARACTERS,
} from 'components/AddQueryModal/AddQueryForm';
import styles from './TextCount.module.scss';

type InputText = string | null | undefined;

const calculateSymbols = (text: InputText) =>
  cleanPromptFromHTML(text || '').replaceAll(/Question\s\d+/g, '').length || 0;

interface TextCountProps {
  text: InputText;
  limit?: number;
}

export const TextCount: FC<TextCountProps> = ({
  text,
  limit = QUESTION_MAX_CHARACTERS,
}) => (
  <div className={styles['content-editable-wrapper']}>
    <Typography.Paragraph className={styles['content-editable-count']}>
      {calculateSymbols(text)} / {limit}
    </Typography.Paragraph>
  </div>
);
