const DeleteIcon = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99967 12.6667C3.99967 13.4 4.59967 14 5.33301 14H10.6663C11.3997 14 11.9997 13.4 11.9997 12.6667V6C11.9997 5.26667 11.3997 4.66667 10.6663 4.66667H5.33301C4.59967 4.66667 3.99967 5.26667 3.99967 6V12.6667ZM11.9997 2.66667H10.333L9.85967 2.19333C9.73967 2.07333 9.56634 2 9.39301 2H6.60634C6.43301 2 6.25967 2.07333 6.13967 2.19333L5.66634 2.66667H3.99967C3.63301 2.66667 3.33301 2.96667 3.33301 3.33333C3.33301 3.7 3.63301 4 3.99967 4H11.9997C12.3663 4 12.6663 3.7 12.6663 3.33333C12.6663 2.96667 12.3663 2.66667 11.9997 2.66667Z"
        fill="#969696"
      />
    </g>
  </svg>
);
export default DeleteIcon;
