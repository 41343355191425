import { Button, Form, Space, Typography } from 'antd';
import { useCallback, useState } from 'react';

import { useCreateCategoryContext } from '../../CreateCategoryContext';
import styles from './SubcategoriesStep.module.scss';
import { Subcategories } from './Subcategories/Subcategories';
import { Queries } from './Queries/Queries';
import { SubcategoryNameForm } from './SubcategoryNameForm/SubcategoryNameForm';

export const SubcategoriesStep = () => {
  const form = Form.useFormInstance();
  const { category, setCategory } = useCreateCategoryContext();
  const [addSubCategory, setAddSubCategory] = useState<boolean>(false);

  const onSubCategoryClickHandler = useCallback(() => {
    setAddSubCategory(!addSubCategory);
  }, [addSubCategory]);

  const onSubCategoryCancel = () => {
    form.setFieldValue('subcategoryName', '');
    setAddSubCategory(!addSubCategory);
  };

  const onSubCategoryCreate = () => {
    form
      .validateFields()
      .then((values) => {
        setAddSubCategory(!addSubCategory);
        setCategory((prev) => ({
          ...prev,
          subcategories: [
            ...(prev.subcategories || []),
            {
              name: values.subcategoryName,
              queries: [],
            },
          ],
        }));
        form.resetFields();
      })
      .catch(() => undefined);
  };

  return (
    <Space className={styles.step} size={24} direction="vertical">
      {category.hasSubcategories && (
        <Space className={styles['step-title-wrapper']}>
          <Typography className={styles['step-title']}>
            Subcategories
          </Typography>
          <Button
            className={styles['step-subcategory-button']}
            onClick={onSubCategoryClickHandler}
          >
            Add Subcategory
          </Button>
        </Space>
      )}
      {addSubCategory && (
        <SubcategoryNameForm
          onCancel={onSubCategoryCancel}
          onCreate={onSubCategoryCreate}
        />
      )}
      {category.hasSubcategories ? <Subcategories /> : <Queries />}
    </Space>
  );
};
