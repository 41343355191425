import LogoIcon from 'common/icons/Logo';
import { Typography, message } from 'antd';
import LogoutIcon from 'common/icons/Logout';
import { signOut } from 'firebase/auth';
import { auth } from 'api/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/constants';

import styles from './Header.module.scss';

export const Header = () => {
  const navigate = useNavigate();

  const onLogoutClickHandler = async () => {
    try {
      await signOut(auth);
      navigate(ROUTES.LOGIN);
    } catch (error: any) {
      message.error(error.message as string);
    }
  };

  return (
    <header className={styles.header}>
      <LogoIcon />
      <Typography
        className={styles['header-logout']}
        onClick={onLogoutClickHandler}
      >
        Logout <LogoutIcon />
      </Typography>
    </header>
  );
};
