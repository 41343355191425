import { useState } from 'react';
import { Button } from 'antd';

import { QueryData } from 'pages/CreateCategory/CreateCategoryContext';
import { AddQueryModal } from './AddQueryModal';

export const AddQueryModalWithTrigger = ({
  onCreate,
}: {
  onCreate?: (queryData: QueryData) => void | Promise<void>;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button type="link" onClick={toggleModal}>
        Add query
      </Button>
      <AddQueryModal
        open={open}
        toggleModal={toggleModal}
        loading={loading}
        setLoading={setLoading}
        onCreate={onCreate}
      />
    </>
  );
};
