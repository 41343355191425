import { CSSProperties, FC, ReactNode } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';

import PlusCircleFilledIcon from 'common/icons/PlusCircleFilled';
import styles from 'common/Chip/Chip.module.scss';

interface ChipProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: ReactNode;
  small?: boolean;
  style?: CSSProperties;
}

export const Chip: FC<ChipProps> = ({
  text,
  disabled,
  icon = <PlusCircleFilledIcon />,
  small,
  ...rest
}) => {
  const classNames = clsx(styles.chip, {
    [`${styles['chip--disabled']}`]: disabled,
    [`${styles['chip--small']}`]: small,
  });
  return (
    <button
      type="button"
      className={classNames}
      contentEditable={false}
      {...rest}
    >
      <Typography.Text className={styles['chip-text']}>{text}</Typography.Text>
      {icon}
    </button>
  );
};
