import React, { useEffect, useState } from 'react';
import { App, Collapse, Divider, Popconfirm, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  writeBatch,
} from 'firebase/firestore';
import { db } from 'api/firebaseConfig';
import EditIcon from 'common/icons/Edit';
import DeleteIcon from 'common/icons/Delete';
import { Collections, ROUTES } from 'common/constants';
import {
  Category as CategoryType,
  Subcategory as SubcategoryType,
  Query as QueryType,
} from 'common/types/Queries';
import { deleteCollection } from 'common/helpers/deleteCollection';
import { useLanguage } from 'common/helpers';
import { Subcategory } from './Subcategory';
import { Query } from './Query';

import styles from './Category.module.scss';

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

export const Category = ({
  category,
  categories,
  defaultOpen,
}: {
  category: CategoryType;
  categories: CategoryType[];
  defaultOpen?: boolean;
}) => {
  const [queries, setQueries] = useState<QueryType[]>([]);
  const [subcategories, setSubcategories] = useState<
    (SubcategoryType & { count?: number })[]
  >([]);
  const [allSubcategories, setAllSubcategories] = useState<SubcategoryType[]>(
    []
  );
  const navigate = useNavigate();

  const { notification } = App.useApp();
  const { language } = useLanguage();
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id,
          Collections.Subcategories
        )
      ),
      (snapshot) => {
        const newItems = snapshot.docs.map((doc) => {
          const updateQueryCount = async () => {
            const queryCount = (
              await getCountFromServer(
                collection(
                  db,
                  Collections.Locales,
                  language,
                  Collections.Categories,
                  category.id,
                  Collections.Subcategories,
                  doc.id,
                  Collections.Queries
                )
              )
            ).data().count;
            setSubcategories((prev) =>
              prev.map((prevSubcategory) =>
                prevSubcategory.id === doc.id
                  ? { ...prevSubcategory, count: queryCount }
                  : prevSubcategory
              )
            );
          };

          setTimeout(updateQueryCount, 0);

          return {
            id: doc.id,
            ...doc.data(),
          } as SubcategoryType;
        });
        setSubcategories(newItems);
      }
    );

    return () => unsubscribe();
  }, [language]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id,
          Collections.Queries
        ),
        orderBy('order')
      ),
      (snapshot) => {
        const newItems = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as QueryType)
        );
        setQueries(newItems);
      }
    );

    return () => unsubscribe();
  }, [language]);
  useEffect(() => {
    const categoriesRef = collection(
      db,
      Collections.Locales,
      language,
      Collections.Categories
    );
    const unsubscribeCategories = onSnapshot(
      categoriesRef,
      (categoriesSnapshot) => {
        const subcategoriesPromises = categoriesSnapshot.docs.map(
          (categoryDoc) => {
            const subcategoriesRef = collection(
              db,
              Collections.Locales,
              language,
              Collections.Categories,
              categoryDoc.id,
              Collections.Subcategories
            );
            return getDocs(subcategoriesRef).then((subcategoriesSnapshot) =>
              subcategoriesSnapshot.docs.map((doc) => ({
                categoryId: categoryDoc.id,
                id: doc.id,
                ...doc.data(),
              }))
            );
          }
        );

        Promise.all(subcategoriesPromises).then((subcategoriesArrays) => {
          const allSubcategories = subcategoriesArrays.flat();
          // @ts-ignore
          setAllSubcategories(allSubcategories);
        });
      }
    );

    return () => unsubscribeCategories();
  }, [db, language]);

  const handleDeleteCategory = () => {
    Promise.all([
      deleteCollection(
        db,
        [
          Collections.Locales,
          language,
          Collections.Categories,
          category.id,
          Collections.Queries,
        ],
        [Collections.Questions]
      ),
      deleteCollection(
        db,
        [
          Collections.Locales,
          language,
          Collections.Categories,
          category.id,
          Collections.Subcategories,
        ],
        [Collections.Queries, Collections.Questions]
      ),
      deleteDoc(
        doc(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id
        )
      ),
    ])
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Category was deleted successfully',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Category was not deleted',
        });
      });
  };

  const handleEditCategory: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    navigate(ROUTES.EDIT_CATEGORY.replace(':id', category.id));
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      const oldIndex = queries.findIndex((item) => item.id === active.id);
      const newIndex = queries.findIndex((item) => item.id === over.id);
      const nextItems = arrayMove(queries, oldIndex, newIndex);
      const batch = writeBatch(db);
      for (let i = 0; i < nextItems.length; i += 1) {
        const query1 = nextItems[i];
        const index = nextItems.indexOf(query1);
        const categoryRef = doc(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id
        );
        const queryRef = doc(categoryRef, Collections.Queries, query1.id);
        batch.update(queryRef, { order: index });
      }
      await batch.commit();
    }
  };

  return (
    <Collapse
      className={styles['categories-content-collapse']}
      collapsible="header"
      defaultActiveKey={defaultOpen ? category.id : undefined}
      items={[
        {
          key: category.id,
          label: (
            <div className={styles['categories-content-collapse-header']}>
              <Typography
                className={styles['categories-content-collapse-header-title']}
              >
                {category.name}
              </Typography>
              <div style={{ fontSize: 28, lineHeight: '1' }}>
                {category.emoji}
              </div>
              <Divider type="vertical" />
              <Space
                size={16}
                className={styles['categories-content-collapse-header-items']}
              >
                {subcategories.map((subcategory) => (
                  <div
                    key={subcategory.id}
                    className={
                      styles['categories-content-collapse-header-items-element']
                    }
                  >
                    {subcategory.name} <span>{subcategory.count}</span>
                  </div>
                ))}
                {!!queries.length && (
                  <div
                    className={
                      styles['categories-content-collapse-header-items-element']
                    }
                  >
                    View all Queries <span>{queries.length}</span>
                  </div>
                )}
              </Space>
              <Divider type="vertical" />
              <div
                className={styles['categories-content-collapse-header-icon']}
                onClick={handleEditCategory}
              >
                <EditIcon />
              </div>
              <Popconfirm
                title="Delete the category"
                description="Are you sure to delete this category?"
                onConfirm={handleDeleteCategory}
                okText="Yes"
                cancelText="No"
                onPopupClick={stopPropagation}
              >
                <div
                  className={styles['categories-content-collapse-header-icon']}
                  onClick={stopPropagation}
                >
                  <DeleteIcon />
                </div>
              </Popconfirm>
            </div>
          ),
          forceRender: true,
          children: (
            <div className={styles['categories-content-collapse-content']}>
              {subcategories?.map((subcategory, index) => (
                <Subcategory
                  key={subcategory.id}
                  category={category}
                  subcategory={subcategory}
                  defaultOpen={index === 0}
                  id={subcategory?.id}
                  order={subcategory?.order}
                  language={language}
                  categories={categories}
                  subcategories={allSubcategories}
                />
              ))}
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext
                  items={queries}
                  strategy={verticalListSortingStrategy}
                >
                  {queries?.map((query) => (
                    <Query
                      key={query.id}
                      category={category}
                      query={query}
                      length={queries?.length}
                      id={query?.id}
                      order={query?.order}
                      language={language}
                      categories={categories}
                      subcategories={allSubcategories}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>
          ),
        },
      ]}
    />
  );
};
