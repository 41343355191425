import { ROUTES } from 'common/constants';
import {
  AuthLayout,
  CreateCategoryLayout,
  MainLayout,
} from 'components/Layout';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { CategoriesPage } from 'pages/Categories';
import { CreateCategoryPage } from 'pages/CreateCategory';
import { LoginPage } from 'pages/Login';
import { Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayout />}>
          <Route path={ROUTES.HOME} element={<CategoriesPage />} />
        </Route>
        <Route element={<CreateCategoryLayout />}>
          <Route
            path={ROUTES.CREATE_CATEGORY}
            element={<CreateCategoryPage />}
          />
          <Route
            path={ROUTES.EDIT_CATEGORY}
            element={<CreateCategoryPage isEditing />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
