import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from 'api/firebaseConfig';
import { FormRule } from 'antd';
import {
  cleanPromptFromHTML,
  QUESTION_MAX_CHARACTERS,
} from 'components/AddQueryModal/AddQueryForm';
import { Collections } from '../constants';

export const customValidation = {
  categoryName: () => ({
    async validator(_: FormRule, value: string) {
      const categoriesRef = collection(db, Collections.Categories);
      const categoryQuery = query(
        categoriesRef,
        where('name', '==', value.trim())
      );

      const CategoryWithSameName = await getDocs(categoryQuery);
      if (CategoryWithSameName.size === 0) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error('Category with this name already exists')
      );
    },
  }),

  promptMaxLength: () => ({
    async validator(_: FormRule, value: string) {
      const deserializePrompt = cleanPromptFromHTML(value).replaceAll('\n', '');
      if (deserializePrompt.length <= QUESTION_MAX_CHARACTERS) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(`Maximum is ${QUESTION_MAX_CHARACTERS} characters`)
      );
    },
  }),
};
