import { Button, Form, Input, Space } from 'antd';
import { useCreateCategoryContext } from 'pages/CreateCategory/CreateCategoryContext';
import styles from './SubcategoryNameForm.module.scss';

export const SubcategoryNameForm = ({
  name = 'subcategoryName',
  onCancel,
  onCreate,
  defaultValue,
}: {
  name?: string;
  defaultValue?: string;
  onCancel: () => void;
  onCreate: () => void;
}) => {
  const { isEditing } = useCreateCategoryContext();

  return (
    <Space className={styles['subcategory-name-wrapper']} size={24}>
      <Form.Item
        name={name}
        rules={[
          { required: true, message: 'Please input a subcategory name!' },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Input
          className={styles['subcategory-name-input']}
          placeholder="Name your subcategory"
          defaultValue={defaultValue}
        />
      </Form.Item>
      <Space size={8}>
        <Button
          className={styles['subcategory-name-button-cancel']}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className={styles['subcategory-name-button-add']}
          onClick={onCreate}
        >
          {isEditing ? 'Save' : 'Create'}
        </Button>
      </Space>
    </Space>
  );
};
