import { SetStateAction } from 'react';
import { Button, Divider, Modal, Space, Typography } from 'antd';
import { AddQueryForm } from 'components/AddQueryModal/AddQueryForm';
import { QuestionsProvider } from 'components/AddQueryModal/QuestionsContext';
import { QueryData } from 'pages/CreateCategory/CreateCategoryContext';

import styles from 'components/AddQueryModal/AddQueryModal.module.scss';

export const QUERY_FORM_NAME = 'addQuery';

export const AddQueryModal = ({
  open,
  toggleModal,
  formName = QUERY_FORM_NAME,
  loading = false,
  setLoading = () => undefined,
  onCreate,
  initialValues,
}: {
  open: boolean;
  toggleModal: () => void;
  formName?: string;
  loading?: boolean;
  setLoading?: (value: SetStateAction<boolean>) => void;
  onCreate?: (queryData: QueryData) => void | Promise<void>;
  initialValues?: QueryData;
}) => {
  return (
    <Modal
      open={open}
      onCancel={toggleModal}
      footer={null}
      centered
      closable={false}
      width={800}
      destroyOnClose
    >
      <Space size={20} className={styles.header}>
        <Typography.Title level={3}>
          {initialValues ? 'Edit query' : 'Add query'}
        </Typography.Title>
        <Button onClick={toggleModal}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          form={formName}
          loading={loading}
        >
          Save
        </Button>
      </Space>
      <Divider />
      <QuestionsProvider initialValues={initialValues}>
        <AddQueryForm
          toggleModal={toggleModal}
          setLoading={setLoading}
          onCreate={onCreate}
          name={formName}
          initialValues={initialValues}
        />
      </QuestionsProvider>
    </Modal>
  );
};
