import { useEffect, useState } from 'react';
import { Collapse, Typography } from 'antd';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  writeBatch,
} from 'firebase/firestore';
import { db } from 'api/firebaseConfig';
import { Collections } from 'common/constants';
import {
  Category as CategoryType,
  Query as QueryType,
  Subcategory as SubcategoryType,
} from 'common/types/Queries';
import { Query } from '../Query';

import styles from './Subcategory.module.scss';

export const Subcategory = ({
  category,
  categories,
  subcategory,
  subcategories,
  defaultOpen,
  order,
  id,
  language,
}: {
  category: CategoryType;
  categories: CategoryType[];
  subcategory: SubcategoryType;
  subcategories: SubcategoryType[];
  defaultOpen?: boolean;
  id?: UniqueIdentifier;
  order?: number;
  language: string;
}) => {
  const [queries, setQueries] = useState<QueryType[]>([]);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id as UniqueIdentifier });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id,
          Collections.Subcategories,
          subcategory.id,
          Collections.Queries
        ),
        orderBy('order')
      ),
      (snapshot) => {
        const newItems = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as QueryType)
        );
        setQueries(newItems);
      }
    );
    return () => unsubscribe();
  }, [language]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      const oldIndex = queries.findIndex((item) => item.id === active.id);
      const newIndex = queries.findIndex((item) => item.id === over.id);
      const nextItems = arrayMove(queries, oldIndex, newIndex);
      const batch = writeBatch(db);
      for (let i = 0; i < nextItems.length; i += 1) {
        const query1 = nextItems[i];
        const index = nextItems.indexOf(query1);
        const categoryRef = doc(
          db,
          Collections.Locales,
          language,
          Collections.Categories,
          category.id
        );
        const subcategoryRef = doc(
          categoryRef,
          Collections.Subcategories,
          subcategory.id
        );
        const queryRef = doc(subcategoryRef, Collections.Queries, query1.id);
        batch.update(queryRef, { order: index });
      }
      await batch.commit();
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      key={order}
      {...listeners}
      {...attributes}
    >
      <Collapse
        className={styles['subcategories-content-collapse']}
        collapsible="header"
        defaultActiveKey={defaultOpen ? subcategory.id : undefined}
        items={[
          {
            key: subcategory.id,
            label: (
              <div className={styles['subcategories-content-collapse-header']}>
                <Typography
                  className={
                    styles['subcategories-content-collapse-header-title']
                  }
                >
                  {subcategory.name}
                </Typography>
              </div>
            ),
            children: (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext
                  items={queries}
                  strategy={verticalListSortingStrategy}
                >
                  <div
                    className={styles['subcategories-content-collapse-content']}
                  >
                    {queries.map((query) => (
                      <Query
                        key={query.id}
                        category={category}
                        subcategory={subcategory}
                        query={query}
                        id={query?.id}
                        order={query?.order}
                        language={language}
                        categories={categories}
                        subcategories={subcategories}
                        length={queries?.length}
                      />
                    ))}
                  </div>
                </SortableContext>
              </DndContext>
            ),
          },
        ]}
      />
    </div>
  );
};
