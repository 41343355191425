import { useState } from 'react';
import { Button, Dropdown, Popconfirm, Typography } from 'antd';
import KebabIcon from 'common/icons/Kebab';
import {
  QueryData,
  useCreateCategoryContext,
} from 'pages/CreateCategory/CreateCategoryContext';
import { AddQueryModal } from 'components/AddQueryModal/AddQueryModal';

import styles from './Query.module.scss';

export const Query = ({
  subcategoryIndex,
  query,
  index,
}: {
  subcategoryIndex?: number;
  query: QueryData;
  index: number;
}) => {
  const [open, setOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };
  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  const toggleConfirmation = () => {
    setOpenConfirmation(!openConfirmation);
  };

  const { setCategory } = useCreateCategoryContext();

  const handleDelete = async () => {
    if (!subcategoryIndex && subcategoryIndex !== 0) {
      setCategory((category) => ({
        ...category,
        queries: (category.queries || []).filter((_, i) => i !== index),
      }));
      return;
    }

    setCategory((category) => ({
      ...category,
      subcategories: (category.subcategories || []).map((subcategory, i) => {
        if (i === subcategoryIndex) {
          return {
            ...subcategory,
            queries: (subcategory.queries || []).filter((_, i) => i !== index),
          };
        }
        return subcategory;
      }),
    }));
  };

  const onEdit = () => {
    setOpenDropdown(false);
    setOpen(true);
  };

  const handleEdit = (queryData: QueryData) => {
    if (!subcategoryIndex && subcategoryIndex !== 0) {
      setCategory((prev) => ({
        ...prev,
        queries: prev.queries?.map((q, i) => {
          if (i !== index) {
            return q;
          }
          return queryData;
        }),
      }));
      return;
    }

    setCategory((prev) => ({
      ...prev,
      subcategories: prev.subcategories?.map((subcategory, i) => {
        if (i !== subcategoryIndex) {
          return subcategory;
        }
        return {
          ...subcategory,
          queries: (subcategory.queries || []).map((q, i) => {
            if (i !== index) {
              return q;
            }
            return queryData;
          }),
        };
      }),
    }));
  };

  return (
    <div className={styles['queries-content']}>
      <div className={styles['queries-content-header']}>
        <Typography className={styles['queries-content-header-title']}>
          {query.name}
        </Typography>
        <Dropdown
          placement="bottomRight"
          open={openDropdown}
          onOpenChange={toggleDropdown}
          menu={{
            items: [
              { label: 'Edit', onClick: onEdit, key: 'edit' },
              {
                label: (
                  <Popconfirm
                    open={openConfirmation}
                    title="Are you sure you want to delete this Template query?"
                    description="This action can’t be undone."
                    onConfirm={handleDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Typography.Text>Delete</Typography.Text>
                  </Popconfirm>
                ),
                onClick: toggleConfirmation,
                key: 'delete',
              },
            ],
          }}
          trigger={['click']}
          overlayStyle={{ minWidth: '160px', padding: '8px 0' }}
        >
          <Button
            icon={<KebabIcon />}
            className={styles['queries-content-header-menu']}
            onClick={toggleDropdown}
          />
        </Dropdown>
        <AddQueryModal
          open={open}
          toggleModal={toggleModal}
          onCreate={handleEdit}
          initialValues={query}
        />
      </div>
      {!!query.questions?.length && (
        <div className={styles['queries-content-questions']}>
          <Typography
            className={`${styles['queries-content-tag']} ${styles['queries-content-questions-tag']}`}
          >
            Questions
          </Typography>
          <div className={styles['queries-content-questions-list']}>
            {query.questions
              .sort((a, b) => a.key.localeCompare(b.key))
              .map(({ key, question }) => (
                <Typography.Paragraph
                  key={key}
                  className={styles['queries-content-questions-message']}
                >
                  <strong
                    className={styles['queries-content-questions-message-key']}
                  >
                    &lt;{key}&gt;
                  </strong>{' '}
                  {question}
                </Typography.Paragraph>
              ))}
          </div>
        </div>
      )}
      <div className={styles['queries-content-prompt']}>
        <Typography
          className={`${styles['queries-content-tag']} ${styles['queries-content-prompt-tag']}`}
        >
          prompt
        </Typography>
        <Typography className={styles['queries-content-prompt-message']}>
          {query.prompt}
        </Typography>
      </div>
    </div>
  );
};
